// Pill

.Pill {
  display: inline-block;
  font-size: 0.75rem;
  color: rgb(71, 80, 93);
  border-radius: rem(20);
  text-align: center;
  padding: 0.5rem 1rem;
  background-color: var(--color-light-grey);
}

.Pill--primary {
  @extend .Pill;
  background-color: var(--color-pale-blue);
  color: var(--color-blue);
}