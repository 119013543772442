// Box
// custom styles for the MUI Box

.Box-gradient--white {
  position: absolute;
  left: 1px;
  right: 1px;
  bottom: 1px;
  height: rem(100);
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  z-index: 5;
  pointer-events: none;
}
