// Breadcrumbs

$circleOuterSize: 4.125rem;
$circleInnerSize: 2.75rem;

.Breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  position: relative;
  z-index: 1000;

  // set colors for Breadcrumb
  --color-grey: rgb(114, 115, 131);
  --color-medium-grey: rgb(154, 160, 166);
  --color-light-grey: rgba(229, 231, 235, 1);

  --color-blue: rgb(29, 175, 255);
  --color-pale-blue: rgb(195, 231, 250);

  --color-green: rgb(15, 191, 132);
  --color-pale-green: rgba(122, 220, 187, 0.6);

  --bar-color: 	var(--color-blue);
  --back-color: var(--color-light-grey);

}

[class*="makeStyles-paper-"] .Breadcrumbs,
.tvs-MuiPaper-root > .Breadcrumbs {
  //margin-top: -70px;
  //margin-right: -80px;
  //margin-left: -80px;
  //margin-bottom: 2.125rem;
  padding: 2.125rem;
  padding-right: 80px;
  padding-left: 80px;
  box-shadow: 0 5px 5px -3px rgba(24, 28, 32, 0.07), 0 3px 14px 2px rgba(24, 28, 32, 0.09), 0 8px 10px 1px rgba(24, 28, 32, 0.05);
}

.Breadcrumb-step {
  display: flex;
  align-items: center;
}

  .Breadcrumb-icon {

  }

    .Breadcrumb-number {
      font-size: 1.25rem;
      color: var(--color-medium-grey);
      font-weight: 500;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 2.75rem;
      height: 2.75rem;
      border-radius: 2.75rem;
      margin: 0;
      background-color: var(--color-white);
      border: solid 2px var(--color-medium-grey);
    }

  .Breadcrumb-content {
    padding-left: rem(16);
  }

    .Breadcrumb-title {
      margin: 0 0 0.625rem;
      max-width: 4.875rem;
      color: var(--color-grey);
      line-height: 1.13;
    }

    .Breadcrumb-status {
      font-size: 0.75rem;
      color: rgb(71, 80, 93);
      border-radius: rem(20);
      text-align: center;
      padding: 0.5rem 1rem;
      background-color: var(--color-light-grey);
    }

// status modifiers

.Breadcrumb-step {

  // when on the step and is in progress
  &.in-progress {
    --bar-color: 	var(--color-blue);

    .Breadcrumb-number {
      border-color: var(--color-white);
      font-weight: bold;
      color: var(--bar-color);
    }

    .Breadcrumb-title {
      font-weight: 900;
      color: var(--color-blue);
    }

    .Breadcrumb-status {
      background-color: var(--color-pale-blue);
      color: var(--color-blue);
    }

  }

  // when not on the step and has progress
  &.has-progress {
    --bar-color:	var(--color-green);

    .Breadcrumb-number {
      border-color: var(--color-white);
    }

    .Breadcrumb-status {
      background-color: var(--color-pale-green);
      color: var(--color-green);
    }

  }

  // when step has progress or is in progress
  // show the meter
  &.in-progress,
  &.has-progress {

    .Breadcrumb-icon {
      width: $circleOuterSize;
      height: $circleOuterSize;
      border-radius: 4.125rem;
      padding: 0.688rem;
      box-shadow: inset 0 5px 9px 0 rgba(0, 0, 0, 0.18);
      background-color: var(--color-light-grey);

      position: relative;
      z-index: 1;

      &:before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--bar-color);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        box-shadow: inset 0 5px 9px 0 rgba(0, 0, 0, 0.18);
      }

    }

  }

  // when off the step and completed
  &.completed {

    .Breadcrumb-number {
      background-color: var(--color-green);
      border-color: var(--color-green);
      color: var(--color-white);
    }

    .Breadcrumb-status {
      background-color: var(--color-pale-green);
      color: var(--color-green);
    }

  }

  // pending
  &.pending {

    .Breadcrumb-status {
      background-color: var(--color-white);
      border: solid 2px var(--color-medium-grey);
      padding: 0.375rem 1rem;
    }

  }

}

// inspired from https://codepen.io/aqib98/pen/PXpaOP
/**
* $step is set to 5 by default, meaning you can only use percentage classes in increments of five (e.g. 25, 30, 45, 50, and so on). This helps to reduce the size of the final CSS file. If you need a number that doesn't end in 0 or 5, you can change the text percentage while rounding the class up/down to the nearest 5.
*/
$step: 5;
$loops: round(100 / $step);
$increment: 360 / $loops;
$half: round($loops / 2);
@for $i from 0 through $loops {
  .Breadcrumb-progress.progress--#{$i * $step}:before {
    @if $i < $half {
      $nextDeg: 90deg + ($increment * $i);
      background-image: linear-gradient(90deg, var(--back-color) 50%, transparent 50%, transparent), linear-gradient($nextDeg, var(--bar-color) 50%, var(--back-color) 50%, var(--back-color));
    }
    @else {
      $nextDeg: -90deg + ($increment * ($i - $half));
      background-image: linear-gradient($nextDeg, var(--bar-color) 50%, transparent 50%, transparent), linear-gradient(270deg, var(--bar-color) 50%, var(--back-color) 50%, var(--back-color));
    }
  }
}
