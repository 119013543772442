// Dropzone

.Dropzone {
  height: rem(460);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #9aa0a6;
  border-radius: rem(8);
  margin-bottom: rem(16);
  
  &:hover {
    border-color: #1dafff;
    background-color: rgba(29, 175, 255, 0.1);
  }
  
}

.Dropzone--large {
  
}

.Dropzone-description {
  text-align: center;
  font-size: 1.5rem;
}

.Dropzone-specs {
  color: #9aa0a6;
  text-align: center;
  font-size: 0.938rem;
  margin-top: rem(50);
}