.Card-button {
  cursor: pointer;

  &:hover {
    box-shadow: 0px 5px 5px -3px rgb(29 175 255 / 20%), 0px 8px 10px 1px rgb(29 175 255 / 14%), 0px 3px 14px 2px rgb(29 175 255 / 12%);
  }

  &-selected {
    background-color: rgba(29, 175, 255, 0.2) !important;

    &:hover {
      box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    }
  }

  &-label {
    width: 100%;
    font-size: 0.75rem;
  }
}
