// NBCU Peacock brand colors
$black: #000000;
$dark-grey: #3f3f3f;
$yellow: #fccc12;
$dark-yellow: #c6a510;
$orange: #ff7112;
$red: #ef1541;
$purple: #6e55dc;
$blue: #069de0;
$green: #05ac3f;

// NBCU font files
@font-face {
  font-family: PeacockSans;
  src:  url('./fonts/nbcu/peacocksans-medium.woff2') format('woff2'),
        url('./fonts/nbcu/peacocksans-medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: PeacockSans;
  src:  url('./fonts/nbcu/peacocksans-bold.woff2') format('woff2'),
        url('./fonts/nbcu/peacocksans-bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: PeacockSans;
  src:  url('./fonts/nbcu/peacocksans-book.woff2') format('woff2'),
        url('./fonts/nbcu/peacocksans-book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

// NBCU Custom styles
.nbcu-theme {
  & .tvs-MuiAppBar-root {
    background-color: $black;
  }

  & .tvs-MuiAppBar-root .tvs-MuiToolbar-root {
    background-color: $black;
  }

  & .tvs-MuiDrawer-root {
    // Spacing for NBCU/tvSci themed footer
    margin-bottom: 40px;
  }

  & .tvs-MuiDrawer-root .tvs-MuiDrawer-paper {
    background-color: $dark-grey;
  }

  & .Nav-exit-btn {
    background-color: #faca13;
    color: #13171a;

    &:hover {
      background-color: #faca1399;
    }
  }

  & .Nav-create-campaign:hover,
  & .Launch-btn:hover {
    background-color: $dark-yellow;
  }

  & .tvs-MuiOutlinedInput-root.tvs-Mui-focused .tvs-MuiOutlinedInput-notchedOutline {
    border-color: $black;
  }

  & .tvs-MuiInputBase-root.tvs-Mui-focused fieldset.tvs-MuiOutlinedInput-notchedOutline {
    border-color: $black;
  }

  & .tvs-MuiFormLabel-root[data-shrink="true"] {
    color: $black;
  }

  & .tvs-MuiFormLabel-root.tvs-Mui-focused {
    color: $black;
  }

  & .CreateAccount {
    background-image: none;
  }

  & .tvs-MuiButton-containedSecondary:not(.tvs-Mui-disabled):not(.tvs-MuiButtonGroup-groupedContained):not(.Nav-create-campaign):not(.Launch-btn) {
    background-color: $blue;
    color: #fff;
  }

  & .Launch-btn {
    background-color: $yellow;
    color: $black;
  }

  & .tvs-MuiCircularProgress-colorPrimary,
  .tvs-MuiCircularProgress-colorSecondary {
    color: #435159;
  }

  & .--background-colorbar {
    background-image: none;
    background-position: left bottom;
    background-repeat: repeat-x;
    border-bottom: 18px solid $yellow;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .Breadcrumbs {
    --color-blue: #069de0;
    --color-nbcu-green: rgb(5, 172, 63);
    --color-pale-nbcu-green: rgba(5, 172, 63, 0.2);
    --color-nbcu-black: rgb(19, 23, 26);
    --color-nbcu-yellow: rgb(252, 204, 18);
  }

  & .Breadcrumb-status.in-progress {
    background-color: $yellow;
    color: $black;
  }

  & .Breadcrumb-step {
    &.has-progress {
      --bar-color:	var(--color-nbcu-green);

      .Breadcrumb-status {
        background-color: var(--color-pale-nbcu-green);
        color: var(--color-nbcu-black);
      }
    }

    &.in-progress {
      --bar-color: 	var(--color-nbcu-yellow);

      .Breadcrumb-number, .Breadcrumb-title, .Breadcrumb-status {
        color: var(--color-nbcu-black);
      }

      .Breadcrumb-status {
        background-color: var(--color-nbcu-yellow);
      }
    }

    &.completed {
      .Breadcrumb-number {
        background-color: var(--color-nbcu-green);
        border-color: var(--color-nbcu-green);
      }

      .Breadcrumb-status {
        background-color: var(--color-pale-nbcu-green);
        color: var(--color-nbcu-black);
      }

    }
  }

  .tvs-MuiButton-textSecondary {
    color: $blue;
  }

  .peacockText {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }

  .Vertical-divider {
    background-color: $dark-grey;
  }
}
