// MuiDrawer

.tvs-MuiDrawer-root {

	.tvs-MuiDrawer-paper {
		background-color: var(--color-primary);
		color: var(--color-white);

		a {
			color: var(--color-white);
			text-decoration: none;
		}

	}

	.tvs-MuiListItemIcon-root,
	.tvs-MuiListSubheader-root {
		color: var(--color-white);
	}

	.Drawer-nav_wrap {
		display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
	}

}