// Button & MuiButton overrides

// these buttons get shadows
.tvs-MuiButton-root.tvs-MuiButton-contained,
.tvs-MuiButton-root.tvs-MuiButton-outlined {
  box-shadow: 0 5px 5px -3px hsla(0, 0%, 0%, 0.2),
    0 3px 14px 2px hsla(0, 0%, 0%, 0.12), 0 8px 10px 1px hsla(0, 0%, 0%, 0.14);
  text-decoration: none;
}

.tvs-MuiButton-sizeLarge.Button--large {
  font-size: 25px;
  min-width: 207px;
  min-height: 66px;
}

// MUI has no added class for "medium"
.tvs-MuiButton-root.Button--medium {
  font-size: 21px;
  min-width: 185px;
  min-height: 59px;
}

.tvs-MuiButton-sizeSmall.Button--small {
  //font-size: 16px;
  //min-width: 120px;
  //min-height: 46px;
  font-weight: 500;
  font-size: 0.75rem;
}

.tvs-MuiButton-disableElevation {
  box-shadow: none !important;
}

.Button-choice-gray {
  .tvs-MuiFormControlLabel-root {
    width: 95%;
  }

  .tvs-MuiCheckbox-root {
    display: none;
  }

  .tvs-MuiFormControlLabel-label {
    padding: rem(16) rem(25);
    border-radius: 10px;
    background-color: #727383;
    width: 100%;
    box-shadow: 0 5px 5px -3px rgba(24, 28, 32, 0.07),
      0 3px 14px 2px rgba(24, 28, 32, 0.12),
      0 8px 10px 1px rgba(24, 28, 32, 0.1);
  }

  .tvs-MuiCheckbox-root.Mui-checked + .tvs-MuiFormControlLabel-label {
    background-color: #1dafff;
    box-shadow: none;
    background-repeat: no-repeat;
    background-position: 90% center;
  }
}

.Button-choice{
  .tvs-MuiFormControlLabel-root {
    width: 95%;
  }

  .tvs-MuiCheckbox-root {
    display: none;
  }

  .tvs-MuiFormControlLabel-label {
    font-size: 1rem;
    padding: rem(16) rem(25);
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    width: 100%;
    box-shadow: 0 5px 5px -3px rgba(24, 28, 32, 0.07),
      0 3px 14px 2px rgba(24, 28, 32, 0.12),
      0 8px 10px 1px rgba(24, 28, 32, 0.1);
  }

  .tvs-MuiCheckbox-root.Mui-checked + .tvs-MuiFormControlLabel-label {
    background-color: rgb(231, 245, 253);
    box-shadow: none;
    background-image: url(../images/icon-check--blue.png);
    background-repeat: no-repeat;
    background-position: 90% center;
  }
}

.Button-choice.full {
  .tvs-MuiFormControlLabel-root {
    width: 100%;
  }

  .tvs-MuiFormControlLabel-label {
    font-size: .875rem;
  }
}

a.activeMenuItem {
  color: #1dafff;
}

.tvs-MuiButton-root {
  &.Button-campaign-nav {
    color: #1dafff;
    font-size: 0.9375rem;
    min-width: 120px;
    padding-top: 7px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 7px;
    height: 41px;
  }
}
