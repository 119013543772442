.background-img-css-example{
  background-image: url(../../images/bg-texture2.png);
  background-position: left bottom;
  background-repeat: no-repeat;
}

.background-img-inline-example{
  background-repeat: no-repeat;
  background-size: contain;
}
