// Backgrounds

.--background-texture {
  background-image: url(../images/bg-texture2.png);
  background-position: left bottom;
  background-repeat: no-repeat;
}

.--background-colorbar {
  background-image: url(../images/bg-colorbar.png);
  background-position: left bottom;
  background-repeat: repeat-x;
}

.background-img-inline-example{
  background-repeat: no-repeat;
  background-size: contain;
}
