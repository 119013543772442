// Utilities

.u-align--{
  
  &center {
    text-align: center;
  }
  
  &end {
    text-align: end;
  }
  
  &start {
    text-align: start;
  }
  
}