// CreateAccountPage

.CreateAccount {
  background-image: url(../images/bg-colorbar-wide.png), url(../images/logo/by-tvS-icon.png), url(../images/bg-tesseract.png);
  background-position: left bottom, center bottom, left bottom,;
  background-repeat: repeat-x, no-repeat, no-repeat;
  min-height: calc(100vh - 64px);
  &-header-res {
    min-height: rem(588);
    background-image: url(../images/img-signup.png);
    background-repeat: no-repeat;
    background-position: -30px 150px;
    h3 {
      max-width: rem(366);
      line-height: normal;
    }
  }
  &-header-res {
    background-size: contain;
  }
}
