.tvs-MuiAlert-root {
  font-family: "Noto Sans JP", serif;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: left;

  &.tvs-MuiAlert-filledInfo {
    color: #0e5880;
    background-color: #bfe8ff;
  }
  &.tvs-MuiAlert-filledError {
    color: #ac0029;
    background-color: #ffbfc7;
  }
}
